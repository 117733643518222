<template>
  <section class="showcase-countries">
    <div class="wrapper">
      <div class="showcase-countries__title">
        <h3>{{ $t('country.title') }}</h3>
        <AppSearch
          :placeholder="t('country.placeholder')"
          :search-list="countriesList"
          search-icon
          @onInput="search"
        />
      </div>
      <template v-if="resultList && resultList.length">
        <div class="countries-list scroll-customize">
          <div
            v-for="country in resultList"
            :key="country.id"
            :class="{ active: country.id === activeCountry?.id }"
            class="country"
            @click="setCurrentCountry(country)"
          >
            <img :src="`${config.baseUrl}${country.img_path}`" alt="flag" />
            <span>{{ country.title }}</span>
          </div>
        </div>
      </template>
      <div v-else class="h3">
        {{ $t("empty") }}
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import type { GetCountriesApp } from "~/helpers/parsers/CountriesDTO";
import AppSearch from "~/components/common/AppSearch.vue";
import type { AppSearchEmitType } from "~/types/commons";
const { t } = useI18n();
const config = useRuntimeConfig().public;

const props = defineProps<{
  countriesList: GetCountriesApp[];
  activeCountry?: GetCountriesApp | null;
}>();

const emits = defineEmits<{
  selectCountry: [GetCountriesApp];
}>();

const countryList = ref<GetCountriesApp[]>([]);
const isInputClear = ref<boolean>(true);

function setCurrentCountry(country: GetCountriesApp) {
  emits("selectCountry", country);
}

function search(res: AppSearchEmitType<GetCountriesApp>) {
  countryList.value = res.searchList;
  isInputClear.value = res.isInputClear;
}

const resultList = computed(() => {
  if (isInputClear.value) {
    return props.countriesList;
  }

  return countryList.value;
});
</script>

<style scoped lang="scss">
.showcase-countries {
  background: var(--white);
  border-radius: var(--main-border-radius) 0 0 var(--main-border-radius);
  padding: 30px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 1px 5px 0px rgba(0, 0, 0, 0.09) inset;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  margin: 9px -10px 5px 0;

  & .h3 {
    width: 100%;
    display: block;
    margin-top: 20px;
    text-align: center;
  }

  & .wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    gap: 30px;

    & > .showcase-countries__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;

      h3 {
        margin: 0;
        color: var(--black);
        font-size: 20px;
        font-weight: 700;
        letter-spacing: -0.3px;
      }
    }

    :deep(input) {
      padding: 6px 5px 7px 5px;
      border: none;
      border-radius: 0;
      border-bottom: 0.5px solid var(--grey-dark);
      font-style: normal;
      font-size: 16px;

      &::placeholder {
        font-style: normal;
        font-size: 16px;
      }

      @media screen and (max-width: 1024px) {
        padding: 5px 5px 7px 4px;
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 0.1);
        background: rgba(217, 217, 217, 0.1);
        font-size: 15px;

        &::placeholder {
          color: #454545;
          font-size: 15px;
          font-style: italic;
          font-weight: 100;
          letter-spacing: -0.15px;
        }
      }
    }
  }

  & .countries-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: -10px;
    margin-left: -10px;
    overflow: auto;
    padding-right: 8px;

    & > div {
      margin-top: 10px;
      margin-left: 10px;
      width: calc(33.3333% - 10px);
    }

    @media (max-width: 1024px) {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(50px, 68px));
      margin: 0;
      gap: 15px;
      justify-content: space-evenly;

      > div {
				justify-content: center;
        margin: 0;
        width: 100%;
        background: none;
        border: none;

        img {
          width: 48px;
          height: 50px;
        }

        span {
          display: none;
        }

				&:hover {
					border-radius: 12px;
					background: rgba(215, 235, 255, 0.70);
					box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
				}
      }
    }
  }

  & .country {
    display: flex;
    align-items: center;
    gap: 9px;
    cursor: pointer;
    padding: 4px 8px;
    border: 1px solid transparent;
    border-radius: 4px;
    transition: var(--transition);

    &:hover {
      background: var(--primary-20);
      border: 1px solid var(--primary);
    }

    &.active {
      border: 1px solid var(--primary);
      background: var(--primary-20);
      & > span {
        font-weight: 600;
      }
    }

    & > img {
      width: 26px;
      min-width: 26px;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      border: 1px solid transparent;
    }

    & > span {
      font-size: 16px;
      font-weight: 500;
      line-height: normal;

      @media (max-width: 1024px) {
        word-break: break-all;
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .showcase-countries {
    margin: 0;
  }
}
</style>
