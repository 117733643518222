<template>
  <section class="showcase-services">
    <div
      v-if="!isServicesListLoading"
      class="wrapper"
    >
      <div class="showcase-services__title">
        <h3>{{ $t('service.title') }}</h3>
        <AppSearch
          :placeholder="t('service.placeholder')"
          :search-list="servicesList || []"
          search-icon
          @onInput="search"
        />
      </div>
      <div class="showcase-services__sort-bar">
        <SortBar
            @selectSortParam="setSortParam"
        />
      </div>
      <div class="service-list scroll-customize">
        <template v-if="resultList && resultList.length">
          <div
            v-for="service in resultList"
            :key="service.id"
            :class="{'active':service.id === activeService?.id}"
            class="service"

            @click="() => setCurrentService(service)"
          >
            <div class="service-wrapper">
              <div class="left">
                <img
                  :src="`${config.baseUrl}${service.img_path}`"
                  alt="flag"
                >
                <span>{{ service.title }}</span>
              </div>
              <div class="right">
                <div class="amount">{{ service.phones }} {{ $t('pcs') }}</div>
                <div class="price">{{ service.price }} &#8381;</div>
                <div v-if="!loadingServicesIds?.includes(service?.id)" class="buy-btn btn primary">{{ $t('button.buy') }}</div>
				        <div v-if="loadingServicesIds?.includes(service?.id)" class="buy-btn btn primary"><default-loader /></div>
              </div>
            </div>
            <div class="separator" />
          </div>
        </template>
        <div
          v-else
          class="h3"
        >
          {{ $t('empty') }}
        </div>
      </div>
    </div>
    <div v-else>
      {{ $t('loading') }}
    </div>
  </section>
</template>

<script setup lang="ts">
import type { ServiceListWithPriceType } from '~/server/routes/api-front/prices/by-country/[country]';
import AppSearch from '~/components/common/AppSearch.vue';
import type { AppSearchEmitType } from '~/types/commons';
import DefaultLoader from '~/components/widgets/loaders/DefaultLoader.vue';
const { t } = useI18n();
const config = useRuntimeConfig().public;
const props = defineProps<{
	servicesList: ServiceListWithPriceType[] | null;
	activeService?: ServiceListWithPriceType | null;
	isServicesListLoading: boolean;
  loadingServicesIds?: number[];
}>();

const emits = defineEmits<{
	selectService: [ServiceListWithPriceType],
  selectSortParam: [name: string]
}>();

const SortBar = defineAsyncComponent(() => import('~/components/widgets/sort-components/SortBar.vue'));

function setCurrentService (service: ServiceListWithPriceType) {
  const _item: any = {...service};
  emits('selectService', _item);
}

const serviceList = ref<ServiceListWithPriceType[]>([]);
const isInputClear = ref<boolean>(true);

function search (res: AppSearchEmitType<ServiceListWithPriceType>) {
  serviceList.value = res.searchList;
  isInputClear.value = res.isInputClear;
}
const resultList = computed(() => {
  if (isInputClear.value) {
    return props.servicesList
  }

  return serviceList.value;
});

function setSortParam(name: string) {
  emits('selectSortParam', String(name));
}
</script>

<style scoped lang="scss">
.showcase-services {
	background: var(--white);
	border-radius: var(--main-border-radius);
	padding: 30px;
  backdrop-filter: blur(53px);

  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25), 0px 205px 58px 0px rgba(0, 0, 0, 0) inset;

  display: flex;
	flex-direction: column;
	flex: 1;
	overflow: hidden;

  & .h3 {
    width: 100%;
    display: block;
    margin-top: 20px;
    text-align: center;
  }

	& .wrapper {
		flex: 1;
		display: flex;
		flex-direction: column;
		gap: 30px;
    overflow: hidden;

    .showcase-services__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;

      h3 {
        margin: 0;
        color: var(--black);
        font-size: 20px;
        font-weight: 700;
        letter-spacing: -0.3px;
      }
    }

		:deep(input) {
			padding: 6px 5px 7px 5px;
			border: none;
			border-radius: 0;
			border-bottom: 0.5px solid var(--grey-dark);
			font-style: normal;
			font-size: 16px;

			&::placeholder {
				font-style: normal;
				font-size: 16px;
			}

			@media screen and (max-width: 1024px) {
				padding: 5px 5px 7px 4px;
				border-radius: 10px;
				border: 1px solid rgba(255, 255, 255, 0.10);
				background: rgba(217, 217, 217, 0.10);
				font-size: 15px;

				&::placeholder {
					color: #454545;
					font-size: 15px;
					font-style: italic;
					font-weight: 100;
					letter-spacing: -0.15px;
				}
			}
		}
    .showcase-services__sort-bar {
      padding-right: 10px;
    }
	}

	& .service-list {
		display: flex;
		flex-wrap: wrap;
		overflow: auto;
    padding-right: 8px;
	}

	& .service {
		width: 100%;

		&.active {
			& img {
				border: 1px solid var(--primary);
			}

			& > span {
				font-weight: 600;
			}
		}

		& .left {
			display: flex;
			align-items: center;
			gap: 8px;

			& > img {
				width: 40px;
				min-width: 40px;
				height: 40px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 6px;
				border: 1px solid transparent;
			}

			& > span {
				font-size: 16px;
				font-weight: 500;
				line-height: normal;
			}

      @media screen and (max-width: 860px) {
        & {
          gap: 5px;
        }
        & > span {
          font-size: 14px;
          word-break: break-word;
        }
      }
		}
	}

	& .service-wrapper {
		display: flex;
		align-items: center;
		gap: 9px;
		width: 100%;
		cursor: pointer;
		justify-content: space-between;
    padding: 14px;
    transition: var(--transition);

		&:hover{
      border-radius: 12px;
      background: rgba(215, 235, 255, 0.5);
		}

		@media screen and (max-width: 1200px) {
			flex-direction: column;
			align-items: flex-start;
		}

		@media screen and (max-width: 1024px) {
			flex-direction: row;
		}
	}

	& .right {
		display: flex;
		gap: 40px;
		align-items: center;

		& .buy-btn {
			height: 30px;
			min-height: 30px;
			font-size: 15px;
			line-height: 100%;
			border-radius: 10px;
      background-color: var(--primary-90);
		}

		& .amount {
			color: var(--primary);
			font-size: 13px;
			font-weight: 400;
			line-height: normal;
		}

		& .price {
			background: #daedff;
			color: var(--primary);
			padding: 4px 14px;
			border-radius: 6px;
		}

		@media screen and (max-width: 1024px) {
			gap: 10px
		}
    @media screen and (max-width: 440px) {
      gap: 5px;
      & .buy-btn {
        font-size: 12px;
      }
      & .amount {
        font-size: 11px;
      }
      & .price {
        font-size: 12px;
      }
    }
	}

	& .separator {
		width: 100%;
		height: 1px;
		background: var(--primary-20);
	}

	& .h2 {
		text-align: center;
		margin: 0 auto;
	}
}
</style>
